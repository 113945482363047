.o-main-navigation {
    &__container {
        display: none;
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 4 * $space 6 * $space;

        &:hover {
            background: $grey--light;
        }
    }
    &__link-icon {
        font-size: $font-size--xlarge-plus;
        width: 40px;
        margin-right: 4 * $space;
    }
}


;@import "sass-embedded-legacy-load-done:1119";