@include media(xxl) {
    .t-hero-carousel {
        padding-bottom: $space * 10;

        &--navigation-wrapper {
            display: flex;
        }

        &:hover {
            .t-hero-carousel__button-wrapper {
                visibility: visible;
            }
        }

        &--navigation-multi-sliders {
            display: flex;

            .t-hero-carousel__button-placeholder {
                display: none;
            }

            .t-hero-carousel__button-wrapper {
                visibility: visible;
            }

            .t-hero-carousel__button-left.button,
            .t-hero-carousel__button-right.button {
                background-color: white;
                .fa {
                    color: $grey--darker;
                }
            }
            .t-hero-carousel__button-left.button[disabled],
            .t-hero-carousel__button-right.button[disabled] {
                .fa {
                    color: $grey--dark;
                }
            }
        }

        &__button-inner {
            display: block;
        }

        .o-header-slider__bottom-container-inner {
            padding: 0 20% 0 ($space * 24);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1475";