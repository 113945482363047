.o-product-highlight {
    &__brushed-text {
        margin-bottom: 2 * $space;
    }

    &__image-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6 * $space;
    }

    &__detail {
        display: grid;
        row-gap: 6 * $space;
    }

    &__headline {
        line-height: 32px !important;
        @include line-clamp(2);
    }

    &__price {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: end;
        column-gap: 2 * $space;
    }
}

;@import "sass-embedded-legacy-load-done:1125";