.product-gpsr {
    &__panel {
        padding-left: $space * 2;
    }

    &__sub-headline {
        margin: $space * 2 0;
    }
}

;@import "sass-embedded-legacy-load-done:1380";