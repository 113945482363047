@include media(xxl) {
    .cart-v2 .t-cart {
        &__main-container {
            display: flex;
        }

        &__no-products {
            flex: 1;
        }

        &__left-container {
            width: percentage(calc(2/3));
            margin-right: $space * 8;
            padding: ($space * 10) 0;
        }

        &__right-container {
            padding: ($space * 10) ($space * 2);
            width: percentage(calc(1/3));
            position: sticky;
            top: 0;
            max-height: 700px;
        }

        &__sticky-checkout {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:822";