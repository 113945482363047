@include media(xxl) {
    .t-homepage {
        &__user-lists {
            &-container {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 14 * $space;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1483";