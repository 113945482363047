.ca-content--plus {
    .ca-header h1 {
        font-weight: bolder;
    }

    &__points-overview,
    &__faq {
        margin-bottom: $space * 4;

        .panel__footer {
            display: none;
        }
    }

    &__points-overview {
        .panel__body {
            padding: $space * 3;
            padding-top: $space * 4;
        }
    }

    &__total {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }

    &__points-info {
        width: 50%;
    }

    &__info {
        padding-top: $space * 2;

        &.--blue {
            color: $blue;
        }

        &.--bronze {
            color: #815a3c;
        }

        &.--silver {
            color: $grey--dark;
        }

        &.--gold {
            color: #b38e2b;
        }
    }

    &__expiration-date {
        display: flex;
        align-items: center;
        border: 1px solid $blue;
        border-radius: 2px;
        padding: $space*2 $space*3;
        margin-top: $space * 4;
        background-color: $blue--light;
    }

    &__expiration-text {
        padding-left: $space * 3;
    }

    &__mobile-hidden {
        display: none;
    }
}

;@import "sass-embedded-legacy-load-done:746";