.m-bundle-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
}

;@import "sass-embedded-legacy-load-done:990";