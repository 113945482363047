.product-documents {
    &__list {
        margin: ($space * 3) 0;

        li {
            margin: ($space * 2) 0;
        }

        a {
            font-weight: $font-weight--bold;
            position: relative;
            margin-left: ($space * 6);
        }

        i::before {
            font-size: 17px;
            left: -($space * 5);
            top: 0;
            position: absolute;
        }
    }
}

;@import "sass-embedded-legacy-load-done:434";