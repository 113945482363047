@include media(md) {
    .o-newsletter-signup {
        &__container {
            padding: (8 * $space) (8 * $space);
        }

        &__usps {
            flex-direction: row;
            column-gap: 4 * $space;
            row-gap: 2 * $space;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 6 * $space;
            margin-bottom: 8 * $space;
            max-width: 400px;
        }

        &__form {
            gap: 2 * $space;
            max-width: 450px;
        }

        // success component style
        &__success-container {
            padding: (22 * $space) (8 * $space) (25 * $space) (8 * $space);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1113";