.o-product-list {
    display: flex;
    flex-direction: column;

    &__title {
        @include border($where: 'bottom', $width: 1px, $color: $grey--light);
        padding-bottom: 4 * $space;
    }
    &__content {
        &.-is-empty {
            margin-top: 4 * $space;
            margin-bottom: 2 * $space;
        }
    }
    &__price {
        margin-left: 4 * $space;
    }
    &__action-button {
        display: inline-flex;
        margin-top: 4 * $space;
    }
    &__action-link {
        text-align: right;
        display: none;
        margin-top: 4 * $space;
    }
}

;@import "sass-embedded-legacy-load-done:1233";