.float-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 0 0 9px;
    font-weight: $font-weight--bold;
    font-size: $font-size--small;
    transition: all 200ms;
    opacity: 1;

    &__target-input {
        height: 45px;

        &[disabled] + .float-label {
            color: $grey--dark;
        }

        &.touched.invalid + .float_label {
            color: $red;
        }

        &.hidden + .float-label {
            display: none;
        }

        &.empty:not(:focus):not(select) {
            &::placeholder {
                color: transparent !important;
            }

            & + .float-label {
                top: 9px;
                opacity: 0.5;
                font-weight: $font-weight--base;
                font-size: $font-size--regular;
            }
        }
    }
}

select.float-label__target-input {
    background: #fff url(../../../images/sprite-inputs.png) no-repeat scroll calc(100% - 10px) -8px;
    background-size: $space * 3;

    &[disabled],
    &.disabled,
    &.disabled-indicator,
    .disabled-indicator & {
        background: #fff url(../../../images/sprite-inputs.png) no-repeat scroll calc(100% - 10px) 18px;
        background-size: $space * 3;
    }
}

input.float-label__target-input {
    &.touched {
        &.valid {
            background-position: calc(100% - 10px) -62px;
        }

        &.warning {
            background-position: calc(100% - 10px) -153px;
        }

        &.invalid {
            background-position: calc(100% - 10px) -91px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:773";