.o-product-comparison-v2 {
    &__img {
        position: relative;
        display: block;
        margin: 0 auto;
    }

    &__name {
        height: 36px;
        overflow: hidden;
        margin-bottom: $space *2;
    }

    table {
        margin-top: $space * 5;
        margin-bottom: $space * 8;

        th,
        td {
            @include border($where: 'right', $width: 1px, $color: $grey);
            width: percentage(calc(1 / 4));
        }

        th {
            vertical-align: middle;
        }

        tbody th:nth-child(odd) {
            background-color: transparent;
        }
    }

    &__placeholder {
        display: flex;
        min-height: 628px;
    }

    &__hidden-text {
        .button__text {
            display: none;

            @include media(xxl) {
                display: block;
            }
        }
    }

    &__add-to-cart {
        @include media(xxl) {
            width: auto;
        }
    }

    .m-table--reverse-striped tbody {
        & > tr:nth-child(even),
        & > tr:nth-child(odd):not(:first-child) {
            .m-table__cell--default {
                padding: 20px 40px;
            }
        }

        & > tr:nth-child(odd):first-child {
            .m-table__cell--default {
                padding-top: 40px;
                padding-right: 30px;
                padding-bottom: 20px;
                padding-left: 30px;
            }
        }

        & > tr:last-child {
            td {
                background-color: transparent;
            }
        }
    }

    .m-product-image-v2 {
        margin-bottom: 25px;
    }
}

;@import "sass-embedded-legacy-load-done:1285";