//Styles for Text with extra content like icon
.m-txt {
    &__container {
        flex: 1 1 auto;
        padding-top: $space * 2;
        width: percentage(calc(1 / 2));
    }

    &__left-icon {
        padding-right: $space;
        color: $blue;
    }
}

;@import "sass-embedded-legacy-load-done:1197";