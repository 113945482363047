.shopping-list-usps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
        background-color: #fff;
        box-shadow: $shadow6;
        min-width: 100%;
        padding: $space * 2;
        margin-bottom: $space * 2;

        h3 {
            font-weight: $font-weight--bold;
        }
    }

    &__icon {
        @include border($color: $grey--light, $width: 3px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        min-height: 50px;
        color: $blue;
        font-size: 22px;
        margin-right: $space * 3;
    }

    @include media(xxl) {
        flex-wrap: nowrap;
        margin-left: -($space * 2);
        margin-right: -($space * 2);

        &__item {
            min-width: 0;
            padding: $space * 4;
            margin: 0 ($space * 2);
        }

        &__icon {
            margin-right: $space * 3;
        }
    }
}

;@import "sass-embedded-legacy-load-done:720";