.o-address-form-guest-checkout {
    width: 100%;

    @include media(xl) {
        width: percentage(calc(2 / 3));
        padding-right: $space;
    }

    &__row {
        display: flex;
        flex-direction: column;
        padding: $space * 2 0;
        font-size: $font-size--regular-plus;

        &-required {
            padding-bottom: $space;

            &::after {
                content: "*";
                font-weight: 600;
                color: $blue;
            }
        }

        &-name,
        &-location {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: $space * 2 0;
            gap: $space * 2;

            @include media(lg) {
                flex-wrap: nowrap;
            }
        }

        &-half {
            width: 100%;
        }

        label {
            display: flex;
        }
    }

    &__input {
        padding-top: $space;
    }

    &__required-info {
        display: flex;
        flex-direction: row-reverse;
        margin-top: $space * 3;

        &-text {
            color: $grey--dark;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1443";