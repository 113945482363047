@include media(xl) {
    .t-cart__main-container {
        .t-cart-empty {
            &__content {
                width: 625px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:866";