@include media(md) {
    .o-voucher-code {
        max-width: 630px;
        margin-bottom: $space * 20;

        &__header {
            margin-bottom: $space * 6;
        }

        &__icon-container {
            right: $space * 6;
            transform: translateY(65%);
        }

        &__container {
            &.--action {
                .o-voucher-code__icon-container {
                    transform: translateY(70%);
                }
            }
        }

        &__additional-text {
            &-item {
                display: inline-block;
            }

            &-item:first-child {
                margin-bottom: 0;
                margin-right: $space * 3;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1137";