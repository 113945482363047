.productline {
    h1,
    h2 {
        font-family: $font-family--main;
    }

    h2 {
        margin-bottom: $space * 5;
        font-size: $font-size--xlarge;
        text-align: center;
        color: #273a60; //special color
    }

    &__banner {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100px;

        @include media(md) {
            height: 150px;
        }

        @include media(lg) {
            height: 250px;
        }

        @include media(xl) {
            height: 350px;
        }
    }

    &__headline {
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;

        h1 {
            font-size: 48px;
            line-height: 1;
            max-width: 60%;
        }

        .container {
            display: flex;
            align-items: center;
        }
    }

    &__products {
        padding: ($space * 1) 0 ($space * 6);
        background-color: $grey--light;

        .products-with-attributes {
            margin-top: $space * 6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:767";