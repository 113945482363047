.m-chip {
    padding: 0 $space*3;
    border-radius: 20px;
    border-width: 0;

    &.collapsed:hover,
    &:not(.collapsed) {
        color: white;
        background-color: $blue;
    }
}

;@import "sass-embedded-legacy-load-done:1074";