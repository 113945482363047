@include media(xxl) {
    .order-history-search-bar {
        &__search {
            max-width: 625px;
        }

        &__clear {
            right: $space * 2;
        }

    }
}

;@import "sass-embedded-legacy-load-done:742";