.m-product-prices-v2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    &__value {
        margin-right: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1195";