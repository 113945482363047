.password {
    .panel {
        &__body {
            display: flex;
            flex-wrap: wrap;
            margin-top: $space * 3;

            label {
                font-weight: $font-weight--bold;
            }
        }

        &__footer {
            display: flex;
            justify-content: flex-end;
        }
    }
}

;@import "sass-embedded-legacy-load-done:693";