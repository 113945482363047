label {
    display: inline-block;
    margin-bottom: $space;
    @include media(xl) { margin: 0; }

    &.required::after {
        content: " *";
        font-weight: $font-weight--bold;
        color: $theme-color;
    }

    &.inline {
        display: inline;
    }
}

;@import "sass-embedded-legacy-load-done:775";