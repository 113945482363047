/* ------------------------------------ *\
    ALERTS
\* ------------------------------------ */

.alert {
    position: relative;
    background-color: $grey--light;
    padding: ($space * 2) ($space * 10);
    margin-right: 1px;
    min-height: $space * 8;
    border-radius: $border-radius;
    width: 100%;

    &__icon,
    &__close {
        position: absolute;
        left: 0;
        bottom: 0;
        width: $space * 10;
        padding: 0 ($space * 3);
    }

    &__close {
        top: 0;
        left: auto;
        right: 0;
    }

    &__icon {
        top: -2px;
        color: #fff;
        display: flex;
        align-items: center;
    }

    &__fa {
        width: $space * 4;
        height: $space * 4;
        font-size: 12px;
        line-height: 20px;
        border-radius: 50%;

        &--info {
            background-color: $blue;

            &::before {
                content: $fa-var-info;
            }
        }

        &--success {
            background-color: $green;

            &::before {
                content: $fa-var-check;
            }
        }

        &--warning {
            color: $yellow;
            font-size: $font-size--icon;

            &::before {
                content: $fa-var-exclamation-triangle;
            }
        }

        &--error {
            background-color: $red;

            &::before {
                content: $fa-var-times;
            }
        }
    }

    &--info {
        background-color: $blue--light;
        @include border($color: $blue);
    }

    &--success {
        background-color: $green--light;
        @include border($color: $green);
    }

    &--warning {
        color: $grey--darker;
        background-color: $yellow--light;
        @include border($color: $yellow);
    }

    &--error {
        background-color: $red--light;
        @include border($color: $red);
    }
}

;@import "sass-embedded-legacy-load-done:972";